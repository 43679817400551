<template>
  <div>
    <van-cell-group title="会议信息">
      <van-cell title="会议分类" :value="model.CAT" />
      <van-cell title="会议主题" :value="model.TITLE" />
      <van-cell title="会议地点" :value="model.LOC" />
      <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" />
      <van-cell title="主持人" :value="model.HOST" />

      <van-collapse v-model="activeNames">
        <van-collapse-item title="内容">
          {{ model.CONT }}
        </van-collapse-item>
        <van-collapse-item :title="'出席者 [' + attend.length + ']'">
          <van-row style="text-align: center; padding: 10px">
            <van-col :span="4" v-for="it in attend" :key="it.ID" style="margin: 5px 0;">
              <van-tag type="primary" style="width: 80%; line-height: 25px;">{{ it.TEA_NAME }}</van-tag>
            </van-col>
          </van-row>
        </van-collapse-item>
        <van-collapse-item :title="'缺席者 [' + absence.length + ']'">
          <van-cell v-for="it in absence" :key="it.ID" :title="it.TEA_NAME" :value="it.MEM" />
        </van-collapse-item>
        <van-collapse-item title="照片">
          <van-uploader v-model="fileList" :deletable="false" :show-upload="false" />
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>

    <div style="margin: 35px 15px">
      <van-button type="primary" size="large" block style="margin-bottom: 10px;" @click="goEdit()">编辑</van-button>
      <van-button type="info" size="large" icon="revoke" block @click="$router.go(-1)">返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "sign",
  data() {
    return {
      model: { LST_JOIN: [] },
      fileList: [],
      activeNames: []
    }
  },
  created() {
    this.get();
  },
  computed: {
    attend() { return this.model.LST_JOIN.filter(o => o.STA == 1) },
    absence() { return this.model.LST_JOIN.filter(o => o.STA == 2) }
  },
  methods: {
    get() {
      let self = this;
      let g = this.$route.query.guid;
      if (g !== undefined) {
        this.whale.remote.getResult({
          url: "/api/Mobile/MEET/MinfoApi/Get",
          data: { GUID: g },
          completed(m) {
            self.model = m.DATA;
            console.log('123', self.model)
            m.DATA.LST_PIC_URL.map(o => {
              self.fileList.push({ url: o.Value, key: o.Key })
            })
          }
        })
      }
    },
    goEdit() {
      this.$router.push({ path: '/meet/query/edit', query: { guid: this.$route.query.guid } })
    }
  }
}
</script>
<style scoped></style>